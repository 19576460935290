import { Container, Button } from '@material-ui/core';
import ReactTooltip from 'react-tooltip';
import { Router } from "react-router-dom";
import RouteWrapper from "./components/RouterWrapper"
import Snackbar from './components/Snackbar';
import history from './logic/history';
import routes from './routes';
import { Component } from 'react';
import auth from "./logic/auth"
import Loading from './components/Loading';
import Popup, { popupCaller } from "./components/Popup"
import Header from './components/Header';
import Reactor from './logic/reactor';
import CookieConsent from "./components/CookieConsent"

const pagesCaller = new Reactor()
export { pagesCaller }

class App extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loggedIn: auth.isLoggedIn(),
            initialized: auth.initialized
        }

        this.onUserChange = this.onUserChange.bind(this)
    }

    onUserChange() {
        this.setState({ loggedIn: auth.isLoggedIn(), initialized: true })
    }

    componentDidMount() {
        auth.subscribe(this.onUserChange)
    }

    componentWillUnmount() {
        auth.unsubscribe(this.onUserChange)
    }

    render() {
        const { initialized, loggedIn } = this.state

        if (!initialized) {
            return <Loading />
        }

        return (
            <div>
                <CookieConsent />
                <ReactTooltip clickable={true} borderColor="lightgray" textColor="black" backgroundColor="white" place="top" />
                <Router history={history}>
                    <Header
                        loggedIn={loggedIn}
                        routes={routes}
                    />
                    <br/>
                    <Container maxWidth="md" style={{ marginBottom: "3em", marginTop: "1em" }}>
                        {routes.map(({ savePath, access, exact = false, component, ...props }, i) => (
                            <RouteWrapper
                                key={i}
                                savePath={savePath === undefined || savePath}
                                access={access}
                                exact={exact}
                                component={component}
                                {...props}
                            />
                        ))}
                    </Container>
                </Router>
                <Snackbar />
                <Popup />
            </div>
        );
    }
}

export default App;
