import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { isEqual } from 'lodash';
import imageCompression from 'browser-image-compression';
import { snackbarCaller } from './Snackbar';
import Dropzone from 'react-dropzone'
import Loading from './Loading';

const imageCompressionOptions = {
    maxSizeMB: 0.4,
    useWebWorker: true,
    maxIteration: 100
}

export default class Image extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false
        }

        this.onUploadImage = this.onUploadImage.bind(this)
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextProps, this.props) | !isEqual(this.state, nextState)
    }

    onUploadImage(acceptedFiles) {
        const file = acceptedFiles[0]
        this.setState({ loading: true })
        imageCompression(file, imageCompressionOptions)
            .then(file => {
                return imageCompression.getDataUrlFromFile(file)
            })
            .then(base64 => {
                this.setState({
                    loading: false,
                })
                this.props.onChange(base64)
            }).catch(err => {
                snackbarCaller.activate(
                    `Something went wrong when uploading ${file.name}, are you sure it is an image?`,
                    "error",
                    10000
                )
                this.setState({
                    loading: false
                })
            })
    }

    render() {
        const { base64 } = this.props
        const { loading } = this.state

        if (loading) {
            return <div style={{ height: "100px" }}>
                <Loading />
            </div>
        }

        if (!base64) {
            return (
                <Dropzone maxSize={5000000} disabled={loading} onDrop={this.onUploadImage}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="all-center" style={{ cursor: "pointer", height: "100px" }} {...getRootProps()}>
                            <input {...getInputProps()} />
                            Upload image
                        </div>
                    )}
                </Dropzone>
            )
        }
        return (
            <img style={{ maxWidth: "100%", width: "auto", height: "auto", maxHeight: "300px" }} src={base64} />
        );
    }
};