


function isFunction(obj) {
    var type = typeof obj;
    return type === 'function'
}

function formatTitle(text) {
    return text + " | Codedips"
}

export { formatTitle, isFunction }