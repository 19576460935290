import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { isEqual } from 'lodash';

export default class Text extends Component {
    constructor(props) {
        super(props)

        this.state = {
            text: ""
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextProps, this.props)
    }

    render() {
        const { text, readOnly } = this.props
        return (
            <div className="text">
                {readOnly 
                    ? <p>{text}</p>
                    : <TextField fullWidth variant="outlined" multiline={true} rows={10} value={text} onChange={e => this.props.onChange(e.target.value)} />
                }
            </div>
        );
    }
};