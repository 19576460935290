import React, { Component } from "react"
import AppBar from '@material-ui/core/AppBar';
import { MenuItem, Button, Typography, InputAdornment, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, TextField, Tooltip } from "@material-ui/core";
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import cookieConsent from "./../logic/cookieConsent"
import FeedbackIcon from '@material-ui/icons/Feedback';
import { snackbarCaller } from "./Snackbar";
import Dd from "./Dropdown";
import request from "../logic/request";
//import Logo from "./Logo"
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import DeleteIcon from '@material-ui/icons/Delete';
import { pagesCaller } from "../App";
import auth from "../logic/auth";
import Dispatcher from "../logic/dispatcher"
import config from "./../logic/config"
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Link from "./Link"
import { popupCaller } from "./Popup";
import history from "../logic/history"

const name = config.name

const Dropdown = ({ children, title = "Menu" }) => <Dd title={title}>{children}</Dd>


const BarItem = ({ route, path }) => (
    <MenuItem
        component={props => <Link to={route.path} {...props} />}
        selected={route.path === path}>
        <div style={{ margin: "auto" }}>
            <IconButton color="inherit" style={{ padding: 0, marginRight: "0.2em" }}>
                {route.icon ? route.icon : null}
            </IconButton>
            {route.text}
        </div>
    </MenuItem>
)

const settings = new Dispatcher()

class Header extends Component {
    constructor(props, context) {
        super(props, context)

        const consentAsked = cookieConsent.consentAsked;

        this.state = {
            path: history.location.pathname,
            consentAsked,
            width: window.innerWidth,
            search: "",
            loggedIn: auth.isLoggedIn(),
            admin: auth.user.admin
        }

        this.submitFeedback = this.submitFeedback.bind(this)
        this.showFeedbackOptions = this.showFeedbackOptions.bind(this)
        this.onCookiesChange = this.onCookiesChange.bind(this)
        this.onResize = this.onResize.bind(this)
        this.search = this.search.bind(this)
        this.updateUser = this.updateUser.bind(this)
    }

    componentWillUnmount() {
        this.unlisten();
        window.removeEventListener("resize", this.onResize)
    }

    onResize() {
        this.setState({ width: window.innerWidth })
    }


    onCookiesChange() {
        this.setState({
            consentAsked: cookieConsent.consentAsked
        })
    }

    updateUser() {
        this.setState({
            loggedIn: auth.isLoggedIn(),
            admin: auth.user.admin,
            newNotifications: auth.user.new_notifications
        })
    }

    componentWillUnmount() {
        auth.unsubscribe(this.updateUser)
    }


    componentDidMount() {
        cookieConsent.subscribe(this.onCookiesChange)
        this.unlisten = history.listen((location, action) => {
            if (history.location.pathname !== this.state.path)
                this.setState({ path: history.location.pathname })
        })
        window.addEventListener("resize", this.onResize)
        auth.subscribe(this.updateUser)
    }

    submitFeedback(send, data) {
        if (send) {
            request("/feedback", "POST", {
                body: data,
                requiresAuth: false,
                catchSnack: true
            }, (success, data) => {
                if (success) {
                    popupCaller.activate({ active: false })
                    snackbarCaller.activate("Feedback was successfully submitted!", "success")
                } else {
                    snackbarCaller.activate("Something went wrong... try again later.", "error")
                }
            })
        } else {
            popupCaller.activate({ active: false })
        }
    }

    showFeedbackOptions() {
        popupCaller.activate({
            active: true,
            message: <div>
                Here you can leave feedback on issues or suggestions. It's appreciated!
            </div>,
            callback: this.submitFeedback,
            buttons: [
                { type: "submit", text: "Submit" }
            ],
            inputs: [
                { required: true, rows: 5, text: "Feedback", name: "feedback", type: "text" }
            ]
        })
    }

    search() {
        const { search } = this.state;
        history.push("/search?q=" + search)
    }

    render() {
        let { path, consentAsked, width, admin, notificationsShown, search, loggedIn, newNotifications = false } = this.state;
        let { routes } = this.props;
        routes = routes.filter(r => (!r.access || r.access(loggedIn, auth.user.admin)) && r.position)
        const mobile = width < 1000
        const Parent = mobile ? Dropdown : React.Fragment
        return <AppBar className="header" position="static">
            <Toolbar style={{ backgroundColor: "white", color: "#333" }}>
                <Button color="inherit" onClick={() => history.push("/")} style={{ marginRight: "0.5em" }}>
                    {/*<Logo style={{ marginRight: "0.5em" }} size={40} />*/}
                    {name}
                </Button>
                {routes.filter(r => r.position === "before").map((route, i) => (
                    <BarItem key={i} route={route} path={path} />))}
                {mobile ? <div style={{ flex: 1 }} /> : null}
                <Parent>
                    {routes.filter(r => r.position === "left")
                        .map((route, i) => <BarItem key={i} route={route} path={path} />)}
                    {!mobile ? <div style={{ flex: 1 }} /> : null}
                    {routes.filter(r => r.position === "right")
                        .map((route, i) => <BarItem key={i} route={route} path={path} />)}
                    <Tooltip title="Feedback">
                        <MenuItem key="feedback"
                            onClick={this.showFeedbackOptions}>
                            <FeedbackIcon style={{ margin: "auto" }} />
                        </MenuItem>
                    </Tooltip>
                    {loggedIn ? <MenuItem onClick={auth.logout}>
                        Logout
                    </MenuItem> : null}
                </Parent>
            </Toolbar>
        </AppBar>
    }
}

export default Header
export { settings }