import React, { Component } from "react"
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Card, CardHeader, CardContent, Checkbox, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import auth from "./../logic/auth.js";
import { Link } from "react-router-dom"
import Container from '@material-ui/core/Container';
import Loading from "./Loading"
import { snackbarCaller } from "./Snackbar.js";

const usernameRegex = /^[A-Za-z0-9]+$/

class Register extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: false,
            email: "",
            name: "",
            password: "",
            repassword: "",
            agreeToTerms: false
        }

        this.register = this.register.bind(this)
    }

    isValidEmail(email) {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
    }

    register() {
        let { email, name, password, repassword, agreeToTerms } = this.state
        if(!agreeToTerms) {
            snackbarCaller.activate(
                "You need to agree to the terms of service and privacy policy to register.", 
                "error"
            )
            return
        }
        if (!this.isValidEmail(email)) {
            snackbarCaller.activate("Email is not valid.", "error")
            return
        }
        if (name.length <= 1 || !usernameRegex.test(name) || name.length > 50) {
            snackbarCaller.activate("Name is not sufficient.", "error")
            return
        }
        if (password.length <= 3) {
            snackbarCaller.activate("Passwords is too short.", "error")
            return
        }
        if (password !== repassword) {
            snackbarCaller.activate("Passwords don't match.", "error")
            return
        }
        this.setState({ loading: true })
        auth.register({ email, username: name, password }, (message) => {
            this.setState({ loading: false })
            snackbarCaller.activate(message, "error")
        })
    }

    render() {
        let { email, password, name, repassword, agreeToTerms, loading } = this.state
        if (loading) {
            return <Loading />
        }
        const usernameOk = name.length === 0 || (usernameRegex.test(name) && name.length <= 50)
        return (
            <Container component="main" maxWidth="xs">
                <Card style={{ padding: "1em", height: "100%" }}>
                    <CardHeader>
                        <Avatar>
                            <LockOutlinedIcon />
                        </Avatar>
                    </CardHeader>
                    <Typography style={{ textAlign: "center" }} component="h1" variant="h5">
                        Register
                    </Typography>
                    <CardContent style={{ zIndex: 0 }}>
                        <form noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={e => this.setState({ email: e.target.value })}
                            />
                            <TextField
                                error={!usernameOk}
                                helperText={!usernameOk ? "Can only contain letters, digits and have to be less than 50 characters." : null}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Username"
                                name="name"
                                autoComplete="name"
                                value={name}
                                onChange={e => this.setState({ name: e.target.value })}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                autoComplete="password"
                                value={password}
                                onChange={e => this.setState({ password: e.target.value })}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="repassword"
                                label="Password again"
                                type="password"
                                autoComplete="repassword"
                                value={repassword}
                                onChange={e => this.setState({ repassword: e.target.value })}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={agreeToTerms}
                                        onChange={e => this.setState({ agreeToTerms: !agreeToTerms })}
                                        color="primary"
                                    />
                                }
                                label={<React.Fragment>
                                    I have read and agree to the:
                                    {" "}
                                    <Link target="_blank" to="/termsofservice">terms of service,</Link>
                                    {" "}
                                    and
                                    {" "} 
                                    <Link target="_blank" to="/privacypolicy">privacy policy</Link>
                                    {" "}
                                    of this site.
                                </React.Fragment>}
                            />
                            <Button
                                onClick={this.register}
                                fullWidth
                                variant="contained"
                                color="primary">
                                Register
                            </Button>
                            <br />
                            <br />
                            <Grid item xs={12}>
                                <Link to="/login">
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        )
    }
}

export default Register