import { Card } from "@material-ui/core";
import React, { Component } from "react";
import history from "../logic/history";
import request from "./../logic/request";
import Link from "./Link";
import Loading from "./Loading";

export default class Home extends Component {
    constructor(props) {
        super(props)

        this.state = {
            examples: [],
            loading: true
        }

        this.getExamples = this.getExamples.bind(this)
    }

    componentDidMount() {
        this.getExamples()
    }

    getExamples() {
        request.get("/dip").then(response => {
            this.setState({ examples: response.data, loading: false })
        })
    }

    render() {
        const { examples, loading } = this.state
        if (loading) {
            return <Loading />
        }
        return <div>
            {examples.map(ex => (
                <Link to={"/dip/" + ex.normalized_title}>
                    <Card style={{ padding: "0.5em", margin: "0.5em" }}>
                        <h3>{ex.title}</h3>
                    </Card>
                </Link>
            ))}
        </div>
    }
}