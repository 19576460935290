// The idea is that many components can call this function
// and the reactor will react to these calls, example: Snackbar.js
class Reactor {
    subscribe(callback) {
        this.callback = callback
    }

    unsubscribe() {
        this.callback = null
    }

    activate(...args) {
        if(!this.callback)
            return
        this.callback.apply(this, args)
    }
}

export default Reactor