import React, { Component } from "react"
import Chart from "react-google-charts";
import request from "../logic/request";
import { snackbarCaller } from "./Snackbar";
import Loading from "./Loading";

class Admin extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true
        }

        this.getData = this.getData.bind(this)
    }

    componentDidMount() {
        this.getData()
    }

    getData() {
        request.get("/admin")
            .then(response => this.setState({ loading: false, ...response.data }))
            .catch(() => snackbarCaller.activate("Something went wrong", "error"))
    }

    render() {
        const { loading, n_requests, dip_stats, n_unique_vistors } = this.state

        if (loading) {
            return <Loading />
        }

        const generalFields = [
            { value: n_requests, text: "Number of requests" },
            { value: n_unique_vistors, text: "Unique visitors" }
        ]
        return (
            <div>
                <h3>Admin</h3>
                <h4>General</h4>
                {generalFields.map(field => <div>
                    {field.text}: <b>{field.value}</b>
                </div>)}
                <h4>Dips</h4>
                {dip_stats.map(dip => {
                    return <div>
                        {Object.entries(dip).map(entry => (
                            <div>
                                {entry[0]}: <b>{entry[1]}</b>
                            </div>
                        ))}
                        <br/>
                    </div>
                })}
            </div>
        );
    }
}

                export default Admin
