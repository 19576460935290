import Dispatcher from "./dispatcher.js";
import config from "./config.js"
import request from "./request.js";
import store from "store"
import { snackbarCaller } from "../components/Snackbar.js";

class Auth extends Dispatcher {
    getInitialState() {
        this.initialized = false
        this.loggedIn = false
        this.check = true
        this.user = {}
    }

    constructor() {
        super()

        this.getInitialState()
        this.initialize()

        this.isLoggedIn = this.isLoggedIn.bind(this)
        this.register = this.register.bind(this)
        this.login = this.login.bind(this)
        this.authenticate = this.authenticate.bind(this)
        this.initialize = this.initialize.bind(this)
        this.handleLogin = this.handleLogin.bind(this)
        this.reset = this.reset.bind(this)
        this.getInitialState = this.getInitialState.bind(this)
        this.logout = this.logout.bind(this)
    }

    reset() {
        this.getInitialState()
        window.location.reload();
    }

    initialize() {
        this.authenticate()
    }

    authenticate() {
        request.get("/auth").then(response => {
            const success = response.status === 200
            if(success)
                Object.assign(this.user, response.data)
            this.loggedIn = success
            this.initialized = true
            this.callSubscriberCallbacks()
        }).catch(() => {
            this.loggedIn = false
            this.initialized = true
            this.callSubscriberCallbacks()
        })
    }

    isInitialized() {
        return this.initialized
    }

    isLoggedIn() {
        return this.loggedIn
    }

    handleLogin(response, callback) {
        this.loggedIn = response.status === 200
        if (this.loggedIn) {
            Object.assign(this.user, response.data);
            this.callSubscriberCallbacks()
        } else {
            callback(response.data.detail)
        }
    }

    register({ email, username, password }, callback) {
        request.post("/account", {
            email,
            password,
            username
        })
        .then(data => this.handleLogin(data, callback))
        .catch(error => {
            callback(error.response.data.detail)
        })
    }

    logout() {
        request.delete("/login")
            .then(this.reset)
            .catch(() => snackbarCaller.activate("Something went wrong", "error"))
    }

    login({ email, password }, callback) {
        request.post("/login", {
            email: email,
            password: password
        }).then(data => this.handleLogin(data, callback));
    }
}

var auth = new Auth()

export default auth
