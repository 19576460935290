import React from "react"
import Home from "./components/Home"
import Create from "./components/Create"
import Dip from "./components/Dip"
import { formatTitle } from "./logic/util"
import Register from "./components/Register"
import Login from "./components/Login"
import GDPR from "./components/GDPR"
import Admin from "./components/Admin"
import TermsOfService from "./components/TermsOfService"
import tmp from "./logic/tmp"
//import Admin from "./components/Admin"

const is_admin = (loggedIn, admin) => {
    return admin
}
const is_logged_in = (loggedIn, admin) => loggedIn
const not_logged_in = (loggedIn, admin) => !loggedIn

const routes = [
    {
        path: "/",
        exact: true,
        component: Home,
        title: "Codedips - Easy to understand code examples"
    },
    {
        path: "/create",
        exact: true,
        component: Create,
        position: "left",
        text: "Create",
        access: is_admin,
        redirect: "/login",
        title: formatTitle("Create")
    },
    {
        path: "/edit",
        component: Create,
        access: is_admin,
        redirect: "/login",
        title: formatTitle("Edit")
    },
    {
        position: "right",
        path: "/register",
        text: "Register",
        access: not_logged_in,
        redirect: "/",
        component: Register,
        title: formatTitle("Register")
    },
    {
        position: "right",
        path: "/login",
        text: "Sign in",
        savePath: false,
        access: not_logged_in,
        redirect: () => {
            return tmp.redirect
        },
        component: Login,
        title: formatTitle("Login")
    },
    {
        path: "/privacypolicy",
        exact: true,
        component: GDPR,
        title: formatTitle("Privacy policy")
    },
    {
        path: "/termsofservice",
        exact: true,
        component: TermsOfService,
        title: formatTitle("Terms of service")
    },
    {
        path: "/admin",
        exact: true,
        position: "right",
        text: "Admin",
        component: Admin,
        title: formatTitle("Admin"),
        access: is_admin
    },
    {
        path: "/dip/*",
        component: Dip,
    }
]

export default routes