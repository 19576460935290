import React, { Component } from "react";
import cookieConsent from "./../logic/cookieConsent"
import Cookies from "js-cookie"
import { Button } from "@material-ui/core"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core"
import TermsOfService from "./TermsOfService"
import PrivacyPolicy from "./GDPR"

export default class CookieConsent extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            asked: cookieConsent.consentAsked,
            show: null
        }

        this.onAccept = this.onAccept.bind(this)
        this.onDecline = this.onDecline.bind(this)
    }

    onAccept() {
        Cookies.set("CookieConsent", true, { expires: 365 })
        cookieConsent.consentAsked = true
        cookieConsent.callSubscriberCallbacks()
        this.setState({ asked: true })
    }

    onDecline() {
        Cookies.set("CookieConsent", false, { expires: 365 })
        cookieConsent.consentAsked = true
        cookieConsent.callSubscriberCallbacks()
        this.setState({ asked: true })
    }

    render() {
        const { asked, show } = this.state
        if (asked) {
            return null
        }
        return (
            <Dialog
                fullWidth={true}
                open={true}>
                <DialogTitle>Consent</DialogTitle>
                {show !== null ?
                    <div>
                        <Button style={{ margin: "1em", width: "150px" }} variant="outlined" onClick={() => this.setState({ show: null })}>
                            Go back
                        </Button>
                        {show === "tos" ?
                            <TermsOfService inPopup onClickPP={() => this.setState({ show: "pp" })} />
                            :
                            <PrivacyPolicy />
                        }
                    </div>
                : (
                    <>
                        <DialogContent>
                            By entering this site you agree to the
                        {" "}
                            <Button size="small" variant="outlined" onClick={() => this.setState({ show: "tos" })}>terms of service</Button> and
                        {" "}
                            <Button size="small" variant="outlined" onClick={() => this.setState({ show: "pp" })}>privacy policy</Button>.
                        <br />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                style={{ backgroundColor: "#5959ff", color: "white" }}
                                variant="contained"
                                onClick={this.onAccept}>
                                I accept
                        </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        )
    }
}