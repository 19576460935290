import React, { Component } from "react"
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Link } from "react-router-dom"
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import auth from "./../logic/auth.js";
import Container from '@material-ui/core/Container';
import { snackbarCaller } from "./Snackbar.js";
import Loading from "./Loading"

class Login extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: false,
            email: "",
            password: ""
        }

        this.login = this.login.bind(this)
    }

    login() {
        this.setState({ loading: true })
        let { email, password } = this.state
        auth.login({ email, password }, (message) => {
            this.setState({ loading: false })
            snackbarCaller.activate(message, "error")
        })
    }

    render() {
        let { email, password, loading } = this.state
        if (loading)
            return <Loading />
        return (
            <Container component="main" maxWidth="xs">
                <Card>
                    <CardHeader>
                        <Avatar>
                            <LockOutlinedIcon />
                        </Avatar>
                    </CardHeader>
                    <Typography style={{ textAlign: "center" }} component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <CardContent style={{ zIndex: 0 }}>
                        <form noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={e => this.setState({ email: e.target.value })}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={e => this.setState({ password: e.target.value })}
                            />
                            <Button
                                onClick={this.login}
                                fullWidth
                                variant="contained"
                                color="primary">
                                Sign In
                            </Button>
                            <br />
                            <br />
                            <Grid container>
                                <Grid item xs={12}>
                                    <Link to="/forgot" variant="body2">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <br />
                                <br />
                                <Grid item xs={12}>
                                    <Link to="/register">
                                        Don't have an account? Sign Up
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        )
    }
}

export default Login