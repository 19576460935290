import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { isFunction } from "./../logic/util"
import MetaWrapper from "./MetaWrapper"
import auth from "./../logic/auth"

function redirectTo(redirect) {
    if(isFunction(redirect))
        return redirect()
    if(!redirect)
        return "/"
    return redirect
}

const RouteWrapper = ({ access, savePath, redirect, title, component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (access === undefined || access(auth.loggedIn, auth.user.admin)) {
                    return <MetaWrapper title={title}>
                        <Component {...props} />
                    </MetaWrapper>
                } else {
                    return <Redirect
                        to={{
                            pathname: redirectTo(redirect)
                        }}
                    />
                }
            }}
        />
    )
}

export default RouteWrapper