import React, { useState } from "react"
import { MenuItem, Menu } from "@material-ui/core"
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export default function Dropdown({ title, children, onSelect }) {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState()

    const handleClick = e => {
        setAnchorEl(e.currentTarget);
        setOpen(true)
    }

    return (
        <React.Fragment>
            <MenuItem style={{ minWidth: "min-content", textAlign: "center" }} onClick={handleClick}>
                {title}<ArrowDropDownIcon />
            </MenuItem>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClick={() => setOpen(false)}
                onClose={() => setOpen(false)}>
                {children}
            </Menu>
        </React.Fragment>
    )
}