class Dispatcher {
    constructor() {
        this.callbacks = []
        this.specificCallbacks = {}
    }

    subscribe(callback, event) {
        if (!event) {
            this.callbacks.push(callback)
        } else {
            if (!this.specificCallbacks[event])
                this.specificCallbacks[event] = [callback]
            else
                this.specificCallbacks[event].push(callback)
        }
    }

    unsubscribe(callback, event) {
        if(!event) {
            let index = this.callbacks.indexOf(callback);
            this.callbacks.splice(index, 1)
        }
        if(!this.specificCallbacks[event])
            return
        let index = this.specificCallbacks[event].indexOf(callback)
        this.specificCallbacks[event].splice(index, 1)
    }

    callSubscriberCallbacks(data, event) {
        if (!event) {
            for (let cb of this.callbacks) {
                cb(data)
            }
        } else if(this.specificCallbacks[event]) {
            for(let cb of this.specificCallbacks[event])
                cb(data)
        }
    }
}

export default Dispatcher