import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormControlLabel, Checkbox } from "@material-ui/core"
import { TextField } from "@material-ui/core"
import cloneDeep from "lodash/cloneDeep"
import Reactor from "./../logic/reactor.js"
import { isFunction } from '../logic/util.js';
import { Autocomplete } from '@material-ui/lab';

const popupCaller = new Reactor()
export { popupCaller }

export default class Popup extends Component {
    initialState = {
        active: false,
        written: "",
        inputValues: {},
        callback: null,
        body: null,
        buttons: [],
        toWrite: "",
        message: "",
        confirmMessage: "",
        inputs: [],
        top: false,
        fullWidth: true,
        width: "sm"
    }

    constructor(props, context) {
        super(props, context)

        this.state = cloneDeep(this.initialState)
        this.reset = this.reset.bind(this)
        this.setPopup = this.setPopup.bind(this)
        this.onClose = this.onClose.bind(this)

        popupCaller.subscribe(this.setPopup)
    }

    setPopup(state, cb) {
        if (!state.active) {
            this.reset()
        } else {
            if (state.inputs) {
                const inputValues = {}
                for (const input of state.inputs) {
                    if (input.initial !== undefined)
                        inputValues[input.name] = input.initial
                }
                state.inputValues = inputValues
            }
            // combine initial with new, making it a reset and adding new props
            this.setState(Object.assign(cloneDeep(this.initialState), state), cb)
        }
    }

    reset(cb) {
        this.setState({}, () => this.setState(cloneDeep(this.initialState)))
    }

    onClose() {
        this.reset()
    }

    render() {
        const { inputValues, fullWidth, width, written, callback, body, active, buttons,
            toWrite, message, confirmMessage, inputs, top } = this.state
        const submitCallback = () => callback(true, inputValues)
        const confirmed = (!confirmMessage || written === toWrite) && (inputs.filter(i =>
            !(!i.required || (inputValues[i.name] && (inputValues[i.name].length > 0)))
        ).length === 0)
        if (!active)
            return null
        return (
            <div>
                <Dialog
                    fullWidth={fullWidth}
                    maxWidth={!width ? "sm" : width}
                    classes={{
                        container: top ? "top-popup" : null
                    }}
                    open={active}
                    onClose={this.onClose}>
                    {!message ? null : <DialogTitle id="alert-dialog-slide-title">{message}</DialogTitle>}
                    {confirmMessage ? (
                        <DialogContent>
                            <DialogContentText>
                                If you sure you want to perform this action type <b>"{toWrite}"</b> below:
                            </DialogContentText>
                            <TextField
                                onChange={e => this.setState({ written: e.target.value })}
                                value={written}
                            />
                        </DialogContent>
                    ) : null}
                    {inputs.length > 0 ? inputs.map(({ width, text, name, type, inputComponentProps = {}, InputComponent, rows, variant, initial = "", selectSettings = { noOptionsText: null, options: [] } }, i) => {
                        if (type === "custom")
                            return <InputComponent {...inputComponentProps} callback={callback} />
                        if (type === "checkbox") {
                            return <FormControlLabel
                                key={i}
                                style={{ width: "100%", margin: "1em" }}
                                checked={inputValues[name] === undefined ? initial : inputValues[name]}
                                onChange={e => {
                                    inputValues[name] = e.target.checked
                                    this.setState({ inputValues })
                                }}
                                control={<Checkbox />}
                                label="Public"
                            />
                        }
                        if (type === "select") {
                            const { noOptionsText, options } = selectSettings
                            return <Autocomplete
                                key={i}
                                rows={rows}
                                size="small"
                                style={{ margin: "1em", maxWidth: width }}
                                defaultValue={options[0]}
                                value={!inputValues[name] ? initial : inputValues[name]}
                                onChange={(_, value) => {
                                    inputValues[name] = value
                                    this.setState({ inputValues })
                                }}
                                noOptionsText={noOptionsText}
                                options={options.sort((a, b) => -b[0].localeCompare(a[0]))}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={text}
                                        variant="outlined"
                                    />
                                )}
                            />
                        }
                        return <TextField
                            key={i}
                            size="small"
                            multiline={rows !== undefined}
                            rows={rows}
                            variant={!variant ? "filled" : variant}
                            style={{ margin: "1em", maxWidth: width }}
                            label={text}
                            type={type}
                            value={inputValues[name] === undefined ? initial : inputValues[name]}
                            onChange={e => {
                                inputValues[name] = e.target.value
                                this.setState({ inputValues })
                            }}
                        />
                    }) : null}
                    {!body ? null : (<DialogContent>
                        {isFunction(body) ? body() : body}
                    </DialogContent>)}
                    {!callback && !buttons ? null :
                        <DialogActions>
                            {buttons.map(({ type, text, callback }, i) => {
                                if (type === "submit") {
                                    // if standard submit callback
                                    let onClick = confirmed ? submitCallback : null
                                    // other
                                    if (callback)
                                        onClick = confirmed ? callback : null
                                    return <Button
                                        key={i}
                                        disabled={!confirmed}
                                        onClick={onClick}
                                        color="primary">
                                        {text}
                                    </Button>
                                }
                                return (
                                    <Button onClick={this.onClose} color="primary">
                                        {text}
                                    </Button>
                                )
                            })}
                        </DialogActions>}
                </Dialog>
            </div>
        );
    }
}