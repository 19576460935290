import React, { Component } from "react"
import Reactor from "./../logic/reactor"
import { Snackbar } from "@material-ui/core"
import { Alert as MuiAlert } from "@material-ui/lab"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const snackbarCaller = new Reactor()
export { snackbarCaller }

export default class _Snackbar extends Component {
    queue = []

    constructor(props, context) {
        super(props, context)

        this.state = { active: false, message: "" }

        this.setSnippets = this.setSnippets.bind(this)
        this.onClose = this.onClose.bind(this)

        snackbarCaller.subscribe(this.setSnippets)  
    }

    onClose() {
        if(this.queue.length > 0)
            this.setState(this.queue.pop())
        else
            this.setState({ active: false })
    }

    setSnippets(message, type, duration) {
        if(!this.state.active) {
            this.setState({ active: true, message, type, duration })
        } else {
            this.queue.push({ active: true, message, type, duration })
        }
    }

    render() {
        const { message, active, type, duration } = this.state
        return (
            <Snackbar
                autoHideDuration={!duration ? 2000 : duration}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={active}
                onClick={() => this.setState({ active: false })}
                onClose={this.onClose}>
                <Alert severity={type}>
                    {message}
                </Alert>
            </Snackbar>
        )
    }
}