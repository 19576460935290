import config from "./config";
import axios from "axios"

const request = axios.create({
    baseURL: config.host,
    withCredentials: true,
    headers: {
        "Access-Control-Allow-Origin": "*"
    }
})

class Request {
    get(url) {
        return request.get(url, { crossdomain: true })
    }

    post(url, data) {
        return request.post(url, data, { crossdomain: true })
    }

    put(url, data) {
        return request.put(url, data, { crossdomain: true })
    }

    delete(url, data) {
        return request.delete(url, data, { crossdomain: true })
    }
}


export default new Request()