import React, { Component } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import ReactTooltip from 'react-tooltip';
import { Button } from '@material-ui/core';
import { clone, cloneDeep, isEqual, range, sortBy } from 'lodash';
require('codemirror/mode/python/python');
require('codemirror/mode/javascript/javascript');

function generateId(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export default class Code extends Component {
    tempMarkProps = {}

    constructor(props) {
        super(props)

        this.state = {
            copied: false
        }

        this.annotate = this.annotate.bind(this)
        this.makeCodeUpdate = this.makeCodeUpdate.bind(this)
        this.copy = this.copy.bind(this)
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state)
    }

    annotate() {
        //htmlNode.appendChild(text)
        //this.editor.addWidget(start, htmlNode, false)
        const selected = this.editor.getSelection()
        if (this.editor.getSelection() === "")
            return
        const start = this.editor.getCursor(true)
        const end = this.editor.getCursor(false)
        const className = generateId(30)
        const annotations = this.props.annotations
        this.editor.doc.markText(start, end, {
            className: className + " highlight",
        })
        annotations.push({
            className,
            text: "",
            start,
            end,
            selected
        })
        this.props.onChange({ annotations })
    }


    componentDidMount() {
        this.timeout = setTimeout(this.makeCodeUpdate, 100)
    }

    makeCodeUpdate() {
        clearInterval(this.interval)
        let { annotations } = this.props
        let marks = this.editor.getDoc().getAllMarks()
        let iterate;
        let useMarks = false
        if (marks.length > 0) {
            useMarks = true
            iterate = marks.map(marker => {
                const pos = marker.find()
                marker.clear()
                return pos
            });
            /*if(annotations.length > marks.length) {
                const pos = annotations[annotations.length-1]
                iterate.push({ from: pos.start, to: pos.end })
            }*/
        } else {
            marks.map(m => m.clear())
            iterate = annotations
        }
        const newAnnotations = []
        for (let i = 0; i < iterate.length; i++) {
            const mark = iterate[i]
            let cls;
            let pos;
            if (useMarks) {
                const a = sortBy(annotations, [x => distance(x, mark)])[0]
                newAnnotations.push(a)
                cls = a.className
                pos = { start: mark.from, end: mark.to }
            } else {
                newAnnotations.push(annotations[i])
                cls = annotations[i].className
                pos = mark
            }
            this.editor.doc.markText(pos.start, pos.end, {
                className: cls + " highlight",
            })
        }
        annotations = newAnnotations
        this.interval = setInterval(() => {
            if (!annotations.every(ann => document.getElementsByClassName(ann.className).length > 0))
                return
            clearInterval(this.interval)
            for (const mark of annotations) {
                const cls = mark.className
                const els = document.getElementsByClassName(cls)
                for (const el of els) {
                    // TODO: detta använder dangerouslsethtml så låt inte användaren öppet lägga in
                    // i så fall sanitera 
                    el.setAttribute("data-tip", mark.text)
                    el.setAttribute("data-class", "tooltip")
                    el.setAttribute("data-effect", "solid")
                    el.setAttribute("data-type", "success")
                }
            }
            ReactTooltip.rebuild()
        }, 30)
    }

    componentWillUnmount() {
        clearInterval(this.interval)
        clearInterval(this.timeout)
        clearInterval(this.copyTimeout)
    }

    componentDidUpdate() {
        this.timeout = setTimeout(this.makeCodeUpdate, 100)
    }

    copy() {
        this.setState({ copied: true })
        navigator.clipboard.writeText(this.props.code)
        this.copyTimeout = setTimeout(() => this.setState({ copied: false }), 2 * 1000)
    }

    render() {
        const { code, lang, annotations, readOnly } = this.props
        const { copied } = this.state
        return (
            <div>
                <CodeMirror
                    className={"code" + (readOnly ? " code-readonly" : " ")}
                    value={code}
                    placeholder="code..."
                    editorDidMount={editor => {
                        this.editor = editor
                        if (!readOnly) {
                            this.editor.addKeyMap({
                                "Cmd-K": this.annotate,
                                "Ctrl-K": this.annotate
                            })
                        }
                    }}
                    options={{
                        mode: lang,
                        theme: "material",
                        lineNumbers: true,
                        readOnly
                    }}
                    onBeforeChange={(editor, data, value) => {
                        clearInterval(this.interval)
                        let marks = this.editor.getAllMarks().map(x => x.find())
                        const anns = []
                        let _anns = cloneDeep(annotations)
                        if (marks.length < _anns.length) {
                            _anns = sortBy(_anns, [x => Math.min(...marks.map(pos => distance(x, pos)))])
                            _anns.pop(0)
                        } else if (marks.length > _anns.length) {
                            _anns.push({ "text": "" })
                        }
                        for (let i = 0; i < _anns.length; i++) {
                            const x = _anns[i]
                            marks = sortBy(marks, [pos => -distance(x, pos)])
                            const pos = marks.pop(0)
                            anns.push({
                                ...x,
                                start: pos.from,
                                end: pos.to
                            })
                        }
                        if (!readOnly)
                            this.props.onChange({ code: value, annotations: anns })
                    }}
                /*onChange={(editor, data, value) => {

                }}*/
                />
                {readOnly ? <Button
                    style={{ display: "block", marginLeft: "auto" }}
                    onClick={this.copy}>
                    {copied ? "Copied!" : "Copy"
                    }</Button> : null}
            </div>
        );
    }
};

function _distance(a, b) {
    if (!a || !b)
        return 9999999
    return Math.abs(a.line - b.line) + Math.abs(a.ch - b.ch)
}

function distance(x, pos) {
    return _distance(x.start, pos.from) + _distance(x.end, pos.to)
}