import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import config from "./../logic/config"
import { Button, Container } from "@material-ui/core"

const email = config.contact
const site = config.sitename

function TermsOfService({ inPopup=false, onClickPP }) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <Container maxWidth="md" style={{ marginBottom: "3em" }}>
            <h1>Terms of service</h1>
            <h2>Last update: 2021-09-19</h2>
            <h3>Responsibility</h3>
            <p>
                In no way is {site} and its creators taking responsibility for any consequences of using the site, or entering the websites/links
                that are linked to within it. By entering the site and accepting these terms you agree to all risks involved.
            </p>
            <h3>Age restrictions</h3>
            <p>
                You have to be above 13 years old to use and register on the site.
            </p>
            <h3>Right to data</h3>
            <p>
                By accepting these terms we have the rights to delete any data added by the user to the site and the account itself.
            </p>
            <h3>Privacy policy</h3>
            <p>
                Read the privacy policy {inPopup ? <span style={{ color: "darkblue", cursor: "pointer" }} onClick={onClickPP}>here</span> : <Link to="/privacypolicy">here</Link>}. By using the Site, you agree to be bound by our Privacy Policy,
                which is incorporated into these Terms of service.
            </p>
            <h3>Contact us</h3>
            <p>
                In order to resolve a complaint regarding the site or to receive further information regarding
                use of the site, please contact us at {email}
            </p>            ‍
        </Container>
    )
}

export default TermsOfService