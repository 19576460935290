import React from "react"
import { CircularProgress } from "@material-ui/core"

const Loading = ({ body, size, containerStyle }) => (
    <div style={containerStyle} className="all-center">
        {body}
        <CircularProgress size={size} />
    </div>
)

export default Loading