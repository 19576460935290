import { createBrowserHistory } from 'history';
import { matchPath } from "react-router";
import routes from "./../routes"
import tmp from "./../logic/tmp"

const history = createBrowserHistory()

export default history;

history.pathHistory = [window.location.pathname]

history.listen(location => {
    history.pathHistory.push(location.pathname)
    for (const r of routes) {
        const match = matchPath(location.pathname, { path: r.path, exact: r.exact });
        if (match !== null) {
            if (r.savePath === undefined || r.savePath) {
                tmp.redirect = location.pathname
            } else {
                break
            }
        }
    }
});