import Dispatcher from "./dispatcher.js";
import Cookies from "js-cookie"

class CookieConsent extends Dispatcher {
    constructor() {
        super()
        this.consentAsked = Cookies.get("CookieConsent") !== undefined
    }
}

const cookieConsent = new CookieConsent()
export default cookieConsent