import React, { Component } from "react"
import Loading from "./Loading"
import { Button } from "@material-ui/core"
import request from "../logic/request"
import { segmentTypes } from "./Create"
import { snackbarCaller } from "./Snackbar"
import history from "../logic/history"
import { popupCaller } from "./Popup"
import auth from "../logic/auth"
import { Tags } from "./TagSelector"
import { formatTitle } from "../logic/util"

export default class Dip extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            notFound: false,
            admin: auth.user.admin
        }

        this.getDip = this.getDip.bind(this)
        this.delete = this.delete.bind(this)
        this.onUserChange = this.onUserChange.bind(this)
    }

    onUserChange() {
        this.setState({ admin: auth.user.admin })
    }

    componentDidMount() {
        auth.subscribe(this.onUserChange)
        this.getDip()
    }

    componentWillUnmount() {
        auth.unsubscribe(this.onUserChange)
    }

    getDip() {
        const normalized_title = this.props.location.pathname.split("/").slice(-1)[0]
        request.get(`/dip/${normalized_title}`).then(response => {
            const d = response.data
            if (!d) {
                this.setState({ loading: false, notFound: true })
                return
            }
            document.title = formatTitle(d.title)
            this.setState({
                loading: false,
                ...d
            })
        })
    }

    delete() {
        popupCaller.activate({
            active: true,
            message: "Are you sure you want to delete the dip?",
            callback: () => request.delete("/dip/" + this.state._id)
                .then(response => {
                    if (response.status === 200) {
                        snackbarCaller.activate("Dip was deleted", "success")
                        popupCaller.activate({ active: false })
                        history.push("/")
                    } else {
                        snackbarCaller.activate("Something went wrong", "error")
                    }
                }
                ).catch(() => snackbarCaller.activate("Something went wrong", "error")),
            buttons: [
                { type: "submit", text: "Yes" },
                { text: "No" }
            ]
        })

    }

    render() {
        if (this.state.loading)
            return <Loading />
        if (this.state.notFound)
            return <h2 style={{ marginTop: "3em" }} className="all-center">Dip was not found</h2>

        const { segments, title, tags, _id, admin } = this.state

        return <>
            {!admin ? null : <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <Button color="primary" onClick={() => history.push("/edit/" + _id)}>Edit</Button>
                    <Button color="secondary" onClick={this.delete}>Delete</Button>
                </div>
            </>}
            <h1>{title}</h1>
            <Tags edit={false} tags={tags} />
            {segments.length === 0 || !segments.some(x => !!x.annotations && x.annotations.length > 0) ? null : (
                <p style={{ fontSize: "0.8em" }}>
                    NOTE: you can put the mouse over 
                    {" "}
                    <span data-class="tooltip" data-tip="Hello!" data-effect="solid" className="highlight">
                        colored parts
                    </span> 
                    {" "}
                    to see a tooltip with more information.
                </p>
            )}
            <hr />
            {segments.map(({ type, ...rest }, i) => {
                return <div style={{ margin: "0.5em 0 0.5em" }} key={i}>
                    {segmentTypes[type].component({ readOnly: true, ...rest })}
                </div>
            })}
        </>
    }
}