import React from "react"
import config from "./../logic/config"
import { Container } from "@material-ui/core"

const email = config.contact
const site = config.sitename

function GDPR() {
    return (
        <Container maxWidth="md" style={{ marginBottom: "3em" }}>
            <h1>Privacy Policy</h1>
            <h2>Last update: 2021-09-19</h2>
            <p>
                Here it is described how {site} handles user data.
            </p>
            <h3>Cookies/Browser Local Storage</h3>
            <p>
                To avoid the users having to login everytime they visit the site, cookies are used. These cookies are deemed necessary if
                you sign up on the website. In the cookie a so called Bearer Token is saved which can be used to authenticate
                the user with the server after having logged in. It will expire after a fixed time and thereafter you have to login again and
                obtain a new token. When performing operations requiring authentication on the site this token is used. To clear this
                token, both locally and  on the server, logout from your account. You can remove all cookies
                by simply clearing them in your browser.
            </p>
            <h3>Statistics</h3>
            To know how many visitors the site has, anonymized information is stored about what webpages users go to.
            <h3>Account information</h3>
            <p>
                During registration and the creation of your account we save the provided email, username and hashed password.
                The email will be used for identification in case you forget your password
                but also to be able to send information about updates, new features and or other site-related
                activities. When you create "dips"/pages on the site, this information is naturally saved and shown
                to all users on the site. If you want to remove your account and/or the pages/dips you've created,
                send an email to {email}.
            </p>
            <h3>Contact</h3>
            <p>
                For any questions or requests, contact us at: {email}.
            </p>
            <br />
            <br />
            <br />
            <br />
            <br />
        </Container>
    )
}

export default GDPR