import React, { Component } from 'react';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-searchbox"
import "ace-builds/src-noconflict/theme-solarized_dark"
import "ace-builds/src-noconflict/mode-python"
import "ace-builds/src-noconflict/mode-javascript"
import { Autocomplete, TimelineSeparator } from "@material-ui/lab"
import { Paper, TextField, Button } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import Code from './Code';
import Text from "./Text"
import history from '../logic/history';
import request from "./../logic/request"
import { snackbarCaller } from './Snackbar';
import { popupCaller } from './Popup';
import Image from './Image';
import TagSelector from './TagSelector';

const LANGUAGES = ["javascript", "python"]

const segmentTypes = {
    "code": {
        text: "Code",
        data: {
            code: "",
            lang: "python",
            annotations: []
        },
        component: function ({ annotations, code, lang, index, readOnly, self }) {
            return <>
                {!readOnly ? <>
                    <Autocomplete
                        options={LANGUAGES}
                        onChange={(e, lang) => self.changeSegment(index, { lang })}
                        getOptionLabel={option => option}
                        disableClearable={true}
                        value={lang}
                        style={{ maxWidth: 400, zIndex: 9999 }}
                        renderInput={(params) => <TextField {...params} label="lang" variant="outlined" />}
                    />
                    {annotations.length > 0 ? <h2>Annotations</h2> : null}
                    {annotations.map((ann, i) => (
                        <span style={{ display: "inline-block", margin: "0.5em", width: "calc(50% - 1em)" }}>
                            <TextField
                                label={ann.selected}
                                fullWidth
                                variant="outlined"
                                value={ann.text}
                                multiline={true}
                                rows={5}
                                type="text"
                                onChange={e => self.changeAnnotation(index, "text", i, e.target.value)}
                            />
                        </span>
                    ))}
                    <br />
                </> : null}
                <Code
                    readOnly={readOnly}
                    code={code}
                    onChange={change => self.changeSegment(index, change)}
                    annotations={annotations}
                    style={{ maxWidth: "600px" }}
                    lang={lang}
                />
            </>
        }
    },
    "text": {
        text: "Text",
        data: {
            text: ""
        },
        component: function ({ changeSegment, index, self, ...data }) {
            return <Text {...data} onChange={text => self.changeSegment(index, { text })} />
        }
    },
    "image": {
        text: "Image",
        data: {
            base64: null
        },
        component: function ({ changeSegment, index, self, ...data }) {
            return <Image onChange={base64 => self.changeSegment(index, { base64 })} {...data} />
        }
    }
}

export { segmentTypes }

export default class Create extends Component {
    constructor(props) {
        super(props)


        this.state = {
            title: "",
            tags: [],
            //code: "",
            //lang: "javascript",
            //annotations: [],
            segments: []
        }

        this.changeAnnotation = this.changeAnnotation.bind(this)
        this.create = this.create.bind(this)
        this.addSegment = this.addSegment.bind(this)
        this.segmentOptions = this.segmentOptions.bind(this)
        this.toggleSegment = this.toggleSegment.bind(this)
        this.getDip = this.getDip.bind(this)
    }

    getDip() {
        const id = this.props.location.pathname.split("/").slice(-1)
        request.get("/edit/" + id).then(response => {
            this.setState(response.data)
        }).catch(() => snackbarCaller.activate("Something went wrong", "error"))
    }

    componentDidMount() {
        if (this.props.location.pathname.indexOf("/edit/") !== -1)
            this.getDip()
    }

    changeAnnotation(index, prop, annIndex, value) {
        const { segments } = this.state
        segments[index].annotations[annIndex][prop] = value
        this.setState({ segments })
    }

    cleanSegment({ annotations, ...segment }) {
        if (!!annotations)
            return { annotations: annotations.map(({ show, ...rest }) => rest), ...segment }
        return segment
    }

    create() {
        const { title, tags, segments } = this.state
        const data = { title, tags, segments: segments.map(this.cleanSegment) }
        const url = !this.state._id ? "/create" : "/edit/" + this.state._id
        const msg = !this.state._id ? "created" : "updated"
        request.post(url, data).then(response => {
            if (response.status === 200) {
                snackbarCaller.activate("Dip " + msg, "success")
                history.push("/dip/" + response.data)
            } else {
                snackbarCaller.activate("Something went wrong", "error")
            }
        }).catch(() => {
            snackbarCaller.activate("Something went wrong", "error")
        })
    }

    deleteSegment(index) {
        const { segments } = this.state
        segments.splice(index, 1)
        this.setState({ segments })
    }

    changeSegment(index, change) {
        const { segments } = this.state
        segments[index] = { ...segments[index], ...change }
        this.setState({ segments })
    }

    addSegment(type, index) {
        const { segments } = this.state
        segments.splice(index, 0, ({
            type,
            show: false,
            ...cloneDeep(segmentTypes[type].data)
        }))
        this.setState({ segments })
        popupCaller.activate({
            active: false
        })
    }

    segmentOptions(index) {
        popupCaller.activate({
            active: true,
            fullWidth: false,
            body: (
                <div>
                    {Object.entries(segmentTypes).map(([key, { text }]) => {
                        return <Button style={{ margin: "0.5em" }} onClick={() => this.addSegment(key, index)} variant="outlined">
                            {text}
                        </Button>
                    })}
                </div>
            )
        })
    }

    toggleSegment(index) {
        const { segments } = this.state
        segments[index].show = !segments[index].show
        this.setState({ segments })
    }

    render() {
        const { title, segments, tags } = this.state
        return (
            <>
                <h1>Create post</h1>
                <br />
                <TextField
                    value={title}
                    label="Title"
                    fullWidth
                    variant="outlined"
                    onChange={e => this.setState({ title: e.target.value })}
                />
                <TagSelector tags={tags} onChange={tags => this.setState({ tags })} />
                <br /><br />
                <div style={{ textAlign: "center" }}>
                    <Button onClick={() => this.segmentOptions(0)}>Add segment</Button>
                </div>
                {segments.map(({ show, type, ...rest }, i) => {
                    return <>
                        <div onDoubleClick={() => show ? this.toggleSegment(i) : null}>
                            {!show ? <hr /> : null}
                            {segmentTypes[type].component({ ...rest, index: i, readOnly: show, self: this })}
                            {!show ? <div style={{ display: "flex" }}>
                                <Button onClick={() => this.toggleSegment(i)}>{"Show"}</Button>
                                <Button onClick={() => this.deleteSegment(i)}>Delete</Button>
                            </div> : null}
                            {!show ? <hr /> : null}
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <Button onClick={() => this.segmentOptions(i+1)}>Add segment</Button>
                        </div>
                    </>
                })}
                <br />
                <div style={{ textAlign: "right" }}>
                    <Button variant="outlined" onClick={this.create}>{!this.state._id ? "Create" : "Update"}</Button>
                </div>
            </>
        );
    }
};