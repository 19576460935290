import React, { Component } from "react"
import { TextField, Button, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import request from '../logic/request';

export default class TagSelector extends Component {
    constructor(props) {
        super(props)

        this.state = {
            tag: ""
        }

        this.removeTag = this.removeTag.bind(this)
        this.addTag = this.addTag.bind(this)
        this.addTagSearch = this.addTagSearch.bind(this)
    }

    addTag() {
        const { tags = [] } = this.props;
        tags.push(this.state.tag)
        this.props.onChange(tags)
        this.setState({ tag: "" })
    }

    addTagSearch(tag) {
        const { tags = [] } = this.props
        tags.push(tag)
        this.props.onChange(tags)
    }

    removeTag(i) {
        const { tags } = this.props;
        tags.splice(i, 1)
        this.props.onChange(tags)
    }

    render() {
        const { tag } = this.state;
        const { tags = [], title = "Tags", search = false } = this.props;
        return <>
            <h4>{title}</h4>
            {tags.length < 10 ?
                search ? <TagSearch onAdd={this.addTagSearch} /> :
                    <>
                        <TextField
                            variant="outlined"
                            label="Add tag"
                            value={tag}
                            onKeyDown={e => {
                                if (e.key === "Enter")
                                    this.addTag()
                            }}
                            size="small"
                            onChange={e => this.setState({ tag: e.target.value })}
                            InputProps={{
                                endAdornment: tag.length > 1 ?
                                    <Button variant="contained" color="primary" style={{ whiteSpace: 'nowrap' }} variant="filled" onClick={this.addTag}>
                                        Add tag
                                    </Button>
                                    : null
                            }}
                        />
                        <br />
                    </>
                :
                "Maximum number of tags reached"
            }
            <br />
            <Tags tags={tags} removeTag={this.removeTag} />
        </>
    }
}

function Tags({ tags=[], removeTag }) {
    return tags.length === 0 ? null :
        <>
            {tags.map((t, i) => (
                <Chip
                    color="primary"
                    style={{ margin: "0.5em" }}
                    label={t.toUpperCase()}
                    onDelete={removeTag ? () => removeTag(i) : null}
                />
            ))}
        </>
}

class TagSearch extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            search: "",
            loading: false,
            searchResults: [],
        }

        this.onSearch = this.onSearch.bind(this)
        this.onAdd = this.onAdd.bind(this)
    }

    onSearch(search) {
        this.setState({ search })
        if (search === "" || !search) {
            this.setState({ searchResults: [], loading: false })
            return
        }
        clearInterval(this.timeout)
        this.setState({ loading: true })
        this.timeout = setTimeout(() => {
            request(`/tag-search?q=${this.state.search.toLowerCase()}`, "GET", {}, (success, data) => {
                if (success) {
                    this.setState({ searchResults: data, loading: false })
                }
            });
        }, 500)
    }

    onAdd(link) {
        this.props.onAdd(link)
        this.setState({ search: "" })
    }

    render() {
        const { search, searchResults = [], loading } = this.state
        return (
            <Autocomplete
                style={{ maxWidth: 400, margin: "0.5em 0 0.5em" }}
                size="small"
                disableClearable
                inputValue={search}
                onChange={(_, option) => this.onAdd(option)}
                getOptionLabel={option => option}
                filterOptions={(options, _) => options}
                options={searchResults}
                loading={loading}
                noOptionsText={search === "" ? "Search tags" : "No tags found"}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Search"
                        autoFocus
                        label="Add tag"
                        variant="outlined"
                        onChange={e => this.onSearch(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress style={{ marginBottom: "0.2em" }} color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                )}
            />
        )
    }
}

export { Tags }